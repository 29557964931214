import { createElement } from 'react'

import type { HTMLAttributes } from 'react'
import { classNames } from '~/utils/class-names'

const styles = {
  h1: 'text-2xl  md:text-3xl',
  h2: 'text-xl  md:text-2xl',
  h3: 'text-lg  md:text-xl',
  h4: 'text-md  md:text-lg',
  h5: 'text-sm  md:text-md',
  h6: 'text-xs  md:text-sm',
}

export type TitleProps = HTMLAttributes<HTMLHeadingElement> & {
  as?: keyof typeof styles
  size?: keyof typeof styles
}

export const Title = ({ as = 'h1', size, className, ...props }: TitleProps) =>
  createElement(as, {
    className: classNames('font-medium leading-none', styles[as], size && styles[size], className),
    ...props,
  })
